<template>
	<div>
		<template>
			<v-toolbar
				flat
				color="primary"
				dark
			>
				<v-toolbar-title>{{trainingPlan.name+(trainingPlan.competition?" - versenygyakorlat":"")}}</v-toolbar-title>
			</v-toolbar>
			<v-sheet>
				<v-btn v-if="canManage" color="primary" @click="dialogs.edit.show=true" rounded>Szerkesztés<v-icon>fa-edit</v-icon></v-btn>
				<v-btn v-if="canManage" color="red accent-4" @click="dialogs.deleteTrainingPlan.show=true" rounded>Törlés<v-icon>fa-trash</v-icon></v-btn>
        <p>{{trainingPlan.description}}</p>
        <h2>Gyakorlatok</h2>
        <v-data-table
          :headers="table.headers"
          :items="trainingPlan.ExerciseMU_Training__Plans"
          sort-by="serialN"
        >
					<template v-slot:top>
						<v-tooltip top>
							Hozzáadás
							<template v-slot:activator="{on, attrs}">
								<v-btn fab color="success" v-on="on" v-bind="attrs" @click="dialogs.addExercise.show=true"><v-icon>fa-plus</v-icon></v-btn>
							</template>
						</v-tooltip>
		      </template>
          <template v-slot:item.serialN="{item}">
            {{item.serialN}}.
          </template>
          <template v-slot:item.exercise="{item}">
            {{exercises.find(e => e.id==eMUs.find(eMU => eMU.id==item.eMUId).ExerciseId).name}}
          </template>
          <template v-slot:item.quantity="{item}">
            {{item.quantity}} {{measurementUnits.find(mU => mU.id==eMUs.find(eMU => eMU.id==item.eMUId).MeasurementUnitId).unit}}
          </template>
          <template v-slot:item.actions="{item}">
            <v-tooltip top>
              Mozgatás
              <template v-slot:activator="{on, attrs}">
                <v-btn icon color="info" v-bind="attrs" v-on="on" @click="dialogs.moveExercise.show=true, dialogs.moveExercise.eMUTPId=item.eMUTPId"><v-icon>fa-arrows-alt-v</v-icon></v-btn>
              </template>
            </v-tooltip>
						<v-tooltip top>
              Törlés
              <template v-slot:activator="{on, attrs}">
                <v-btn icon color="red accent-4" v-bind="attrs" v-on="on" @click="dialogs.deleteExercise.show=true, dialogs.deleteExercise.eMUTPId=item.eMUTPId"><v-icon>fa-trash</v-icon></v-btn>
              </template>
            </v-tooltip>
          </template>
        </v-data-table>
			</v-sheet>
		  <v-row justify="center">
		    <v-dialog
		      v-model="dialogs.edit.show"
		      persistent
		      max-width="600px"
		    >
		      <v-card>
		        <v-card-title>
		          <span class="headline">Szerkesztés</span>
		        </v-card-title>
		        <v-card-text>
		          <v-container>
								<v-text-field
									v-model="dialogs.edit.changedTrainingPlan.name"
									prepend-inner-icon="fa-clipboard-list"
									label="Gyakorlat"
									rounded
									outlined
								/>
								<v-textarea
									v-model="dialogs.edit.changedTrainingPlan.description"
									prepend-inner-icon="fa-info-circle"
									label="Leírás"
									rounded
									outlined
								/>
								<v-checkbox
									v-model="dialogs.edit.changedTrainingPlan.competition"
									label="Versenyterv"
								/>
                <v-checkbox
                  v-if="$store.getters.userRole>=roles.nationalCoordinator"
									v-model="dialogs.edit.changedTrainingPlan.public"
									label="Publikus"
								/>
		          </v-container>
		        </v-card-text>
		        <v-card-actions>
		          <v-spacer></v-spacer>
		          <v-btn
		            color="blue darken-1"
		            text
		            @click="dialogs.edit.show = false"
		          >
		            Mégsem
		          </v-btn>
		          <v-btn
		            color="blue darken-1"
		            text
		            @click="change()"
								v-if="dialogs.edit.changedTrainingPlan.name.length>0"
		          >
		            Módosítás
		          </v-btn>
		        </v-card-actions>
		      </v-card>
		    </v-dialog>
				<v-dialog
					v-model="dialogs.deleteTrainingPlan.show"
					persistent
					max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Edzésterv törlése</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								Biztos benne, hogy törli az edzéstervet?
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.deleteTrainingPlan.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="deleteTrainingPlan()"
							>
								Törlés
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog
					v-model="dialogs.addExercise.show"
					persistent
					max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Gyakorlat hozzáadása</span>
						</v-card-title>
						<v-card-text>
							<v-container>
                <v-select
									rounded
									outlined
									prepend-inner-icon="fa-dumbbell"
									label="Gyakorlat"
									v-model="dialogs.addExercise.exercise.ExerciseMeasurementUnitId"
									:items="selectEMU"
									item-value="id"
									item-text="name"
								/>
								<v-row>
									<v-col cols="12" sm="6">
										<v-numeric
											outlined
											rounded
											v-model="dialogs.addExercise.exercise.repetition"
											label="Ismétlés"
											prepend-inner-icon="fa-redo"
											locale="hu-HU"
											min="1"
											useCalculator="false"
										/>
									</v-col>
									<v-col cols="12" sm="6">
										<v-numeric
											rounded
											outlined
											prepend-inner-icon="fa-dumbbell"
											label="Mennyiség"
											min="1"
											v-model="dialogs.addExercise.exercise.quantity"
											precision="1"
											useCalculator="false"
											locale="hu-HU"
										/>
									</v-col>
								</v-row>
								<v-text-field
									rounded
									outlined
									prepend-inner-icon="fa-hourglass-start"
									label="Becsült idő"
									v-model="dialogs.addExercise.exercise.estimatedTime"
								/>
								<div>{{dialogs.addExercise.exercise.ExerciseMeasurementUnitId>-1 ? exercises.find(e => e.id==eMUs.find(eMU => eMU.id==dialogs.addExercise.exercise.ExerciseMeasurementUnitId).ExerciseId).description : ""}}</div>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.addExercise.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="addExercise()"
							>
								Hozzáadás
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
        <v-dialog
					v-model="dialogs.moveExercise.show"
					persistent
					max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Gyakorlat áthelyezése</span>
						</v-card-title>
						<v-card-text>
							<v-container>
                <v-numeric
                  outlined
                  rounded
                  v-model="dialogs.moveExercise.moveTo"
                  label="Cél: "
                  min="1"
                  :max="trainingPlan.ExerciseMU_Training__Plans.length"
                  prepend-inner-icon="fa-dot-circle"
                  locale="hu-HU"
                  useCalculator="false"
                />
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.moveExercise.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="moveExercise()"
							>
								Áthelyezés
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog
					v-model="dialogs.deleteExercise.show"
					persistent
					max-width="600px"
				>
					<v-card>
						<v-card-title>
							<span class="headline">Gyakorlat törlése</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								Biztosan törli a gyakorlatot az edzéstervből?
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.deleteExercise.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="deleteExercise()"
							>
								Törlés
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
	  	</v-row>
	</template>
	</div>
</template>

<script>
	import router from '../router';
	export default {
    props: ['id'],
		data: function(){
			return {
        canManage: false,
        trainingPlan: {},
        exercises: [],
        measurementUnits: [],
        eMUs: [],
        ageGroups: [],
        table: {
          headers: [
            {
              text: "Sorszám",
              align: "center",
              sortable: false,
              filterable: false,
              value: "serialN"
            },
            {
              text: "Gyakorlat",
              align: "center",
              sortable: false,
              filterable: false,
              value: "exercise"
            },
            {
              text: "Ismétlés",
              align: "center",
              sortable: false,
              filterable: false,
              value: "repetition"
            },
            {
              text: "Mennyiség",
              align: "center",
              sortable: false,
              filterable: false,
              value: "quantity"
            },
            {
              text: "Becsült idő",
              align: "center",
              sortable: false,
              filterable: false,
              value: "time"
            },
            {
              text: "Műveletek",
              align: "center",
              sortable: false,
              filterable: false,
              value: "actions"
            }
          ]
        },
        dialogs: {
					edit: {
						show: false,
						changedTrainingPlan: {name: '', description: '', competition: false, public: false}
					},
					deleteTrainingPlan: {
						show: false
					},

					addExercise: {
						show: false,
						exercise: {
							ExerciseMeasurementUnitId: -1,
							repetition: 1,
							quantity: 1,
							estimatedTime: ""
						}
					},
          moveExercise: {
            show: false,
            eMUTPId: -1,
            moveTo: 1
          },
					deleteExercise: {
						show: false,
						eMUTPId: -1
					}
        },
      }
		},
		computed: {
			selectEMU() {
				return this.eMUs.sort((a,b) => a.ExerciseId-b.ExerciseId).map(eMU => {
					return {
						name: this.exercises.find(e => e.id==eMU.ExerciseId).name+" ("+this.measurementUnits.find(mU => mU.id==eMU.MeasurementUnitId).unit+")",
						id: eMU.id
					}
				})

			}
		},
		watch: {

		},
		methods: {
			change: function() {
				this.axios({url: "trainingplan/"+this.$route.params.id, method: "PUT", data: this.dialogs.edit.changedTrainingPlan}).then((response) => {
					if(response.data.success) {
						this.trainingPlan.name=this.dialogs.edit.changedTrainingPlan.name;
						this.trainingPlan.description=this.dialogs.edit.changedTrainingPlan.description;
						this.trainingPlan.competition=this.dialogs.edit.changedTrainingPlan.competition;
						this.dialogs.edit.show=false;
						this.$store.commit('setSnack','A módosítás sikeresen megtörtént.')
					}
				})
			},
			deleteTrainingPlan: function() {
				this.axios({url: "trainingplan/"+this.$route.params.id, method: "DELETE"}).then((response) => {
					if(response.data.success){
						this.$store.commit('setSnack','A törlés sikeresen megtörtént.')
						this.dialogs.deleteTrainingPlan.show=false;
						this.$router.push('/trainingplans')
					}
				})
			},

			addExercise: function() {
				this.axios({url: "trainingplan/"+this.$route.params.id+"/exercise", method: "POST", data: this.dialogs.addExercise.exercise}).then((response) => {
					if(response.data.success){
						this.trainingPlan.ExerciseMU_Training__Plans.push(response.data.data)
						this.$store.commit('setSnack','A hozzáadás sikeresen megtörtént.')
						this.dialogs.addExercise.show=false
					}
				})
			},
      moveExercise: function() {
        this.axios({url: "trainingplan/"+this.$route.params.id+"/exercise", method: "PUT", data: {eMUTPId: this.dialogs.moveExercise.eMUTPId, moveTo: this.dialogs.moveExercise.moveTo}}).then((res) => {
          if(res.data.success) {
						const EMUTP = this.trainingPlan.ExerciseMU_Training__Plans.find(_EMUTP => _EMUTP.eMUTPId==this.dialogs.moveExercise.eMUTPId)
						if(EMUTP.serialN!=this.dialogs.moveExercise.moveTo)
							this.trainingPlan.ExerciseMU_Training__Plans.filter(eMUTP => this.dialogs.moveExercise.moveTo > EMUTP.serialN ? eMUTP.serialN<=this.dialogs.moveExercise.moveTo&&eMUTP.serialN>EMUTP.serialN : eMUTP.serialN>=this.dialogs.moveExercise.moveTo&&eMUTP.serialN<EMUTP.serialN).forEach(eMUTP => {
								if(this.dialogs.moveExercise.moveTo > EMUTP.serialN) eMUTP.serialN--
								else eMUTP.serialN++
							})
						this.$store.commit('setSnack','A mozgatás sikeresen megtörtént.')
						EMUTP.serialN=this.dialogs.moveExercise.moveTo
						this.dialogs.moveExercise.show=false
          }
        })
      },
			deleteExercise: function() {
				this.axios({url: "trainingplan/"+this.$route.params.id+"/exercise/"+this.dialogs.deleteExercise.eMUTPId, method: "DELETE"}).then((res) => {
					if(res.data.success) {
						const EMUTPIndex= this.trainingPlan.ExerciseMU_Training__Plans.findIndex(eMUTP => eMUTP.eMUTPId == this.dialogs.deleteExercise.eMUTPId);
						this.trainingPlan.ExerciseMU_Training__Plans.filter(eMUTP => eMUTP.serialN>this.trainingPlan.ExerciseMU_Training__Plans[EMUTPIndex].serialN).forEach(eMUTP => {
							eMUTP.serialN--;
						})
						this.trainingPlan.ExerciseMU_Training__Plans.splice(EMUTPIndex,1)
						this.$store.commit('setSnack','A törlés sikeresen megtörtént.')
						this.dialogs.deleteExercise.show=false
					}
				})
			}
		},
		mounted(){
			this.axios({url: "trainingplan/"+this.$route.params.id, method: "GET"}).then((response) => {
        if(response.data.success) {
          this.trainingPlan = response.data.data.trainingPlan
					this.dialogs.edit.changedTrainingPlan = {name: this.trainingPlan.name, description: this.trainingPlan.description, competition: this.trainingPlan.competition, public: this.trainingPlan.public}
          this.measurementUnits = response.data.data.measurementUnits
          this.exercises=response.data.data.exercises;
          this.eMUs=response.data.data.eMUs;
          this.ageGroups=response.data.data.ageGroups
          this.canManage=response.data.data.canManage;
        }
			})
		}
	}
</script>
